import { useEffect, useMemo, useState } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Form from '@amzn/awsui-components-react/polaris/form';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import SettingsPanel from './SettingsPanel';
import { JOB_LEVELS } from '@/common/constants';
import CategoriesPanel from './CategoriesPanel';
import LeadershipPrinciplePanel from '@/components/common/LeadershipPrinciplePanel';
import { CategoryResource, ManagerPathResource, Nullable, OptionalNumber } from '@/models';
import { InfoLink } from '@/components/common/links';
import { useCategories, useCategoryActions } from '@/api/category';
import { useManagerPromoPathActions } from '@/api/promo-path';
import { useLayoutTools } from '@/common/ToolsPanel';
import { useAppContext } from '@/contexts';
import useNavigator from '@/common/hooks/use-navigator';
import { ManagePromoPathsPage } from '@/common/pages';

const jobLevelOptions = JOB_LEVELS.map((level) => ({ value: `${level}`, label: `L${level}` }));

interface FormParams {
  promoPath?: Nullable<ManagerPathResource>;
  role?: string;
}

const PromoPathForm = ({ role = 'TAM', promoPath }: FormParams) => {
  const [name, setName] = useState('');
  const [requiredSummaries, setRequiredSummaries] = useState<OptionalNumber>();
  const [baseLevel, setBaseLevel] = useState(jobLevelOptions[1]);
  const [targetLevel, setTargetLevel] = useState(jobLevelOptions[2]);
  const [lps, setLps] = useState<string[]>([]);
  const [allowUploads, setAllowUploads] = useState(true);
  const [categories, setCategories] = useState<CategoryResource[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { setToolsForSection } = useLayoutTools();
  const { currentUser, spoofUser } = useAppContext();
  const { defaultCategories } = useCategories(role);
  const { actions: promoPathActions } = useManagerPromoPathActions(promoPath?.id);
  const { actions: categoryActions } = useCategoryActions();
  const { goToPage } = useNavigator();

  const originalCategories = useMemo(
    () => (promoPath?.categories.length ? promoPath.categories : defaultCategories),
    [promoPath?.categories, defaultCategories]
  );

  useEffect(() => {
    if (!promoPath?.categories.length) {
      setCategories(defaultCategories);
    }
  }, [promoPath?.categories, defaultCategories]);

  useEffect(() => {
    if (promoPath) {
      setName((prevName) => (prevName === promoPath.name ? prevName : promoPath.name));
      const recordBaseLevel = jobLevelOptions.find((opt) => opt.value === promoPath.startLevel.toString());
      const recordTargetLevel = jobLevelOptions.find((opt) => opt.value === promoPath.targetLevel.toString());
      if (recordBaseLevel) {
        setBaseLevel((prevLevel) => (prevLevel.value === recordBaseLevel.value ? prevLevel : recordBaseLevel));
      }
      if (recordTargetLevel) {
        setTargetLevel((prevLevel) => (prevLevel.value === recordTargetLevel.value ? prevLevel : recordTargetLevel));
      }
      setAllowUploads(promoPath.allowsAttachments);
      setLps((prevLps) =>
        promoPath.suggestedLPs &&
        prevLps.every((lp) => promoPath.suggestedLPs.includes(lp)) &&
        promoPath?.suggestedLPs.every((lp) => prevLps.includes(lp as string))
          ? prevLps
          : promoPath.suggestedLPs
      );
      setRequiredSummaries(promoPath.totalRequired);
      const recordCategoryIds = promoPath.categories.map((category) => category.id);
      setCategories((prevCategories) =>
        prevCategories.every((category) => recordCategoryIds.includes(category.id)) &&
        recordCategoryIds.every((id) => prevCategories.map((category) => category.id).includes(id)) &&
        prevCategories.every((category) => category.id.length > 0)
          ? prevCategories
          : promoPath.categories
      );
    }
  }, [promoPath]);

  const saveCategories = async () => {
    const hasCategoryChanged = (category: CategoryResource) => {
      const original = originalCategories.find((item) => item.id === category.id);
      // Create new if no original
      if (!original) return true;
      return (
        original.label.trim().toLowerCase() !== category.label.trim().toLowerCase() ||
        original.description.trim().toLowerCase() !== category.description.trim().toLowerCase()
      );
    };
    const categoryIds = categories.filter((c) => !!c.id).map((c) => c.id);
    await Promise.all(
      originalCategories
        .filter((c) => !categoryIds.includes(c.id) && !c.isDefault)
        .map(async (category) => categoryActions.delete(category.id))
    );
    const newCategoryIds = await Promise.all(
      categories.map(async (category) => {
        const params = { role, name: category.label, description: category.description };
        let result: Nullable<CategoryResource>;
        if (hasCategoryChanged(category)) {
          if (category.isDefault || !category.id) {
            result = await categoryActions.create(params);
          } else {
            result = await categoryActions.update({ ...params, id: category.id });
          }
        }
        return result?.id ?? category.id;
      })
    );
    return newCategoryIds;
  };

  const savePromoPathItem = async () => {
    try {
      const categoryIds = await saveCategories();
      const baseParams = {
        name,
        role,
        categoryIds,
        startLevel: parseInt(baseLevel.value, 10),
        targetLevel: parseInt(targetLevel.value, 10),
        suggestedLPs: lps,
        allowAttachments: allowUploads,
        totalRequired: requiredSummaries,
        manager: spoofUser?.alias ?? currentUser?.alias ?? '',
      };
      if (promoPath?.id) {
        await promoPathActions.update(baseParams);
      } else {
        await promoPathActions.create(baseParams);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onSave = () => {
    setIsSaving(true);
    savePromoPathItem().then(() => goToPage(ManagePromoPathsPage));
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => goToPage(ManagePromoPathsPage)} variant="link" disabled={isSaving}>
            Cancel
          </Button>
          <Button onClick={() => onSave()} variant="primary" loading={isSaving} disabled={isSaving}>
            {isSaving ? 'Saving...' : `${promoPath?.id ? 'Update' : 'Create'} promo path`}
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <SettingsPanel
          nameParams={{ value: name, onChangeValue: setName }}
          requiredCountParams={{ value: requiredSummaries ?? undefined, onChangeValue: setRequiredSummaries }}
          baseLevelParams={{ value: baseLevel, onChangeValue: setBaseLevel, options: jobLevelOptions }}
          targetLevelParams={{ value: targetLevel, onChangeValue: setTargetLevel, options: jobLevelOptions }}
          uploadsParams={{ value: allowUploads, onChangeValue: setAllowUploads }}
        />
        <Container
          header={
            <Header
              variant="h2"
              info={
                <InfoLink
                  onShowTools={() => setToolsForSection({ sectionKey: 'promoPathCategory' })}
                  ariaLabel="Additional info on a work summary category."
                />
              }
            >
              Categories
            </Header>
          }
        >
          <CategoriesPanel categories={categories} setCategories={setCategories} />
        </Container>
        <LeadershipPrinciplePanel
          label="Recommended leadership principles"
          description="Recommended LPs employees use in their work summaries."
          selections={lps}
          onChangeValue={setLps}
          onShowTools={setToolsForSection}
        />
      </SpaceBetween>
    </Form>
  );
};

export default PromoPathForm;
