import { capitalize } from 'lodash';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import { Nullable, WorkSummaryResource } from '@/models';
import KeyValuePair from '@/components/common/KeyValuePair';
import { CategorySelection, LeadershipPrincipleSelection } from './common-components';

interface ManagerViewParams {
  workSummary: Nullable<WorkSummaryResource>;
}

const ManagerView = ({ workSummary }: ManagerViewParams): JSX.Element => (
  <ColumnLayout columns={2} variant="text-grid">
    <KeyValuePair label="Title">{workSummary?.title || '-'}</KeyValuePair>
    <KeyValuePair label="Type">{capitalize(workSummary?.workSummaryType)}</KeyValuePair>
    <KeyValuePair label="Categories">
      <CategorySelection categories={workSummary?.categories} />
    </KeyValuePair>
    <KeyValuePair label="Leadership principles">
      <LeadershipPrincipleSelection lpItems={workSummary?.leadershipPrinciples} />
    </KeyValuePair>
  </ColumnLayout>
);

export default ManagerView;
